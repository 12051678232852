/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.7.1/dist/jquery.min.js
 * - /npm/notie@4.3.1/dist/notie.min.js
 * - /npm/jquery-blockui@2.7.0/jquery.blockUI.min.js
 * - /gh/premento/cdn@6.0.0/fg/bootstrap/js/bootstrap.bundle.min.js
 * - /gh/jovanfever/onyx@46.3.0/js/jquery.bootpag.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
